import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";

const Login = React.lazy(() => import("pages/Login"));
const UploadAudio = React.lazy(() => import("pages/UploadAudio"));
const ProposalQueue = React.lazy(() => import("pages/ProposalQueue"));
const ConversationSummary = React.lazy(() => import("pages/ConversationSummary"));
const PatientSelection = React.lazy(() => import("pages/PatientSelection"));
const MedicalAssessment = React.lazy(() => import("pages/MedicalAssessment"));
const LiveDemo = React.lazy(() => import("pages/LiveDemo"));


const ProjectRoutes = () => {
    return (
        <React.Suspense fallback={<>Loading...</>}>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/applications" element={<UploadAudio />} />
                    <Route path="/proposal-queue" element={<ProposalQueue />} />
                    <Route path="/conversation-summary" element={<ConversationSummary />} />
                    <Route path="/patient-selection" element={<PatientSelection />} />
                    <Route path="/medical-assessment" element={<MedicalAssessment />} />
                    <Route path="/live-demo" element={<LiveDemo />} />
                </Routes>
            </Router>
        </React.Suspense>
    ); 
}
export default ProjectRoutes;
