// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root { 
--light_blue_A400:#00b7ff; 
--light_green_A700:#54d703; 
--light_blue_700:#008fc8; 
--black_900_3f:#0000003f; 
--gray_50:#f0faff; 
--black_900:#000000; 
--gray_500_01:#8f8f8f; 
--blue_gray_800:#254866; 
--gray_200_01:#eaeaea; 
--gray_200_02:#ececec; 
--blue_gray_900_01:#303030; 
--blue_600_01:#368ae5; 
--blue_gray_900:#333333; 
--gray_200_03:#efefef; 
--gray_200_04:#f0eeee; 
--gray_600:#7f7f7f; 
--gray_400:#c4c4c4; 
--gray_600_01:#7d7d7d; 
--blue_gray_100:#d9d9d9; 
--gray_500:#a0a0a0; 
--amber_500:#fbbc18; 
--gray_400_3f:#c4c4c43f; 
--blue_600:#358ae5; 
--gray_900:#1e1e1e; 
--gray_200:#eeeeee; 
--gray_300:#dddddd; 
--gray_100:#f4f4f4; 
--gray_400_01:#c0c0c0; 
--gray_400_02:#b8b8b8; 
--white_A700:#ffffff; 
--gray_600_3f:#7b7b7b3f; 
}`, "",{"version":3,"sources":["webpack://./src/styles/color.css"],"names":[],"mappings":"AAAA;AACA,yBAAyB;AACzB,0BAA0B;AAC1B,wBAAwB;AACxB,wBAAwB;AACxB,iBAAiB;AACjB,mBAAmB;AACnB,qBAAqB;AACrB,uBAAuB;AACvB,qBAAqB;AACrB,qBAAqB;AACrB,0BAA0B;AAC1B,qBAAqB;AACrB,uBAAuB;AACvB,qBAAqB;AACrB,qBAAqB;AACrB,kBAAkB;AAClB,kBAAkB;AAClB,qBAAqB;AACrB,uBAAuB;AACvB,kBAAkB;AAClB,mBAAmB;AACnB,uBAAuB;AACvB,kBAAkB;AAClB,kBAAkB;AAClB,kBAAkB;AAClB,kBAAkB;AAClB,kBAAkB;AAClB,qBAAqB;AACrB,qBAAqB;AACrB,oBAAoB;AACpB,uBAAuB;AACvB","sourcesContent":[":root { \n--light_blue_A400:#00b7ff; \n--light_green_A700:#54d703; \n--light_blue_700:#008fc8; \n--black_900_3f:#0000003f; \n--gray_50:#f0faff; \n--black_900:#000000; \n--gray_500_01:#8f8f8f; \n--blue_gray_800:#254866; \n--gray_200_01:#eaeaea; \n--gray_200_02:#ececec; \n--blue_gray_900_01:#303030; \n--blue_600_01:#368ae5; \n--blue_gray_900:#333333; \n--gray_200_03:#efefef; \n--gray_200_04:#f0eeee; \n--gray_600:#7f7f7f; \n--gray_400:#c4c4c4; \n--gray_600_01:#7d7d7d; \n--blue_gray_100:#d9d9d9; \n--gray_500:#a0a0a0; \n--amber_500:#fbbc18; \n--gray_400_3f:#c4c4c43f; \n--blue_600:#358ae5; \n--gray_900:#1e1e1e; \n--gray_200:#eeeeee; \n--gray_300:#dddddd; \n--gray_100:#f4f4f4; \n--gray_400_01:#c0c0c0; \n--gray_400_02:#b8b8b8; \n--white_A700:#ffffff; \n--gray_600_3f:#7b7b7b3f; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
